/***************************************************
  Imports
***************************************************/

import React from "react"

import PageTypeE from "../../templates/PageTypeE"
import List from "../../components/List"

import TitleImg from "../../images/industries-manufacturing.png"

/***************************************************
  Page Data
***************************************************/

const summaryInfo = () => {
  return (
    <>
      <div className="industries-and-finance">
        <p>
          Being able to adapt to ever-changing environment and stand out of the
          fierce competition is never easy in the manufacturing industry. Here
          success requires producing the right products, in the right
          quantities, at the right time, with good quality, and at a price the
          customer is willing to pay. The flexibility to respond to compliance
          standards and the ever-changing needs of customers, such as providing
          real-time visibility into global operations, is also imperative for
          success. Meeting these demands require the ability to make quick
          decisions based on accurate data.
        </p>
      </div>
      <div className="industries-and-finance">
        <h3>
          Optimize Efficiency and Profitability with Microsoft Dynamics NAV
        </h3>
        <p>
          Microsoft Dynamics NAV helps you to respond quickly to rapidly
          changing customer demands, increase adaptability, speed up your
          orders, and improve business performance. Microsoft Dynamics NAV
          offers manufacturers the ability to:
        </p>
      </div>
    </>
  )
}

/***************************************************
  Component
***************************************************/

export default () => {
  return (
    <PageTypeE
      title="Manufacturing"
      description="Helps you to respond quickly to rapidly changing customer demands, increase adaptability, speed up your orders, and improve business performance."
      linkSubPageGroupID="IndustriesGroup"
      subTitle="The Ultimate Manufacturing Experience"
      titleImg={TitleImg}
      summaryInfo={summaryInfo}
      infoList={[
        {
          title: "Integrate collaboration and communication",
          text: (
            <List
              className="dark"
              items={[
                {
                  text: `Deliver a single, real-time view of data from multiple facilities and departments`,
                },
                {
                  text: `Synchronize communication between engineering, manufacturing, and subcontractors`,
                },
                {
                  text: `Optimize planning to minimize inventory while meeting customer requirements`,
                },
                {
                  text: `Share information within your systems through the Web`,
                },
              ]}
            />
          ),
        },
        {
          title: "Efficiently manage projects",
          text: (
            <List
              className="dark"
              items={[
                {
                  text: `Provide an accurate overview of project status`,
                },
                {
                  text: `Track activities and resources at many levels of detail`,
                },
                {
                  text: `Leverage knowledge and content from existing or prior projects`,
                },
              ]}
            />
          ),
        },
        {
          title: "Deliver superior customer service",
          text: (
            <List
              className="dark"
              items={[
                {
                  text: `Gain a comprehensive view of customer information`,
                },
                {
                  text: `Eliminate isolated silos of information`,
                },
                {
                  text: `Create customer self-service opportunities`,
                },
                {
                  text: `Coordinate multi-channel communications with customers`,
                },
                {
                  text: `Collaborate with customers on new product designs`,
                },
              ]}
            />
          ),
        },
        {
          title: "Estimate and quote accurately",
          text: (
            <List
              className="dark"
              items={[
                {
                  text: `Provide accurate quotations based on a real-time view into operations`,
                },
                {
                  text: `Align customer requirements with your capabilities`,
                },
                {
                  text: `Record customer requirements accurately`,
                },
              ]}
            />
          ),
        },
        {
          title: "Provide flexible production planning",
          text: (
            <List
              className="dark"
              items={[
                {
                  text: `Use data from entire supply chain to respond effectively to changes and problems`,
                },
                {
                  text: `Access data through familiar desktop tools`,
                },
                {
                  text: `Support various manufacturing modes such as engineer-to-order, make-to-order, make-to-stock, and mixed/hybrid`,
                },
              ]}
            />
          ),
        },
      ]}
    />
  )
}
